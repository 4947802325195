import React from "react";
import { notification as antdNotification } from "antd";
import { IoClose, IoWarning } from "react-icons/io5";
import { GoInfo } from "react-icons/go";
import { BiCheckCircle } from "react-icons/bi";
import { MdOutlineErrorOutline } from "react-icons/md";
import classNames from "classnames";

// interfaces
import { INotification } from "./INotification";

// components
import Button from "@/shared/Button";

// assets
import styles from "@/assets/styles/components/notification.module.scss";

const mapNotificationIcon = {
  success: BiCheckCircle,
  info: GoInfo,
  warning: IoWarning,
  error: MdOutlineErrorOutline
};

function notification({ actionText, actionProps, type = "info", isPainted, ...props }: INotification) {
  const NotificationIcon = mapNotificationIcon[type];

  antdNotification.config({
    top: 110,
  })

  return antdNotification.open({
    className: classNames(
      styles["locl-notification"],
      styles[`locl-notification_${type}`],
      { [styles["locl-notification_painted"]]: isPainted }
    ),
    closeIcon: <IoClose className={styles["locl-notification__close-icon"]} />,
    btn: (actionText || actionProps) && (
      <Button size="small" type="secondary" {...actionProps}>
        {actionText}
      </Button>
    ),
    icon: <NotificationIcon className="locl-notification__icon" />,
    ...props,
    message: props.message || null,
  });
}

export default notification;
